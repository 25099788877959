<template>
<CRow>
  <CCol col="12" xl="12">
    <CCard>
      <CCardHeader>
        <CCol col="6" class="styleHeader"> {{ $t('message.office') }} </CCol>
      </CCardHeader>
      <CCardBody>
        <div class="row">
            <div class="col-md-6 ">
                <table class="table table-bordered">
                  <tr>
                    <th> {{ $t('message.name')}}  </th>
                    <td>{{office.name  }}</td>
                  </tr>

                </table>
            </div>
        </div>
         <div class="row">
            <div class="col-md-10 ">
                <table class="table table-bordered">
                  <tr>
                    <th> {{ $t('message.users')}}  </th>
                  </tr>
                   <tr v-for=" user in users" :key="user.id">
                    <td>{{ user.name }}</td>
                  </tr>
                </table>
            </div>
        </div>
        <CButton color="btn btn-primary" @click="goBack">{{ $t('message.back')}}</CButton>
      </CCardBody>
    </CCard>
  </CCol>
</CRow>
</template>

<script>
export default {
  name: 'ShowOffice',
  data () {
    return {
      office: [],
      users: []
    }
  },
  created () {
    console.log(this.$route.params.id)
    this.$http
      .get(`${this.$hostUrl}offices/${this.$route.params.id}`)
      .then((response) => {
        console.log(response.data.data)
        this.office = response.data.data
        this.users = this.office.users
      })
  },
  methods: {
    goBack () {
      this.$router.push({ path: '/offices' })
    }
  }
}
</script>
<style scoped>
  th {
    background-color: #643c4f;
    color: #f5f3f3;
  }
</style>
